import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["message"]
  static values = {
    text: String
  }

  copy() {
    navigator.clipboard.writeText(this.textValue)
    this.messageTarget.classList.remove("hidden")
    setTimeout(this.removeMessage.bind(this), 2000);
  }

  removeMessage() {
    this.messageTarget.classList.add("hidden")
  }
}