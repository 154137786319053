import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modalContainer",
    "modal",
    "title",
    "tipsTitle",
    "tips",
    "imgContainer",
    "cancelButton",
    "uploadButton",
    "uploadState",
    "previewState",
    "previewImage"
  ]

  static values = {
    ratio: { type: Number, default: 1.33 },
    image: String
  }

  initialize() {
    this.texts = {
      title: "Mettez en valeur votre photo",
      tipsTitle: "Conseils pour une photo réussie",
      tips: [
        "Assurez-vous que l'image est nette et que les détails importants sont visibles.",
        "Organisez les éléments de l'image de manière équilibrée pour une présentation harmonieuse.",
        "Mettez en avant le sujet principal de votre projet pour attirer l'attention.",
        "Ne surchargez pas l'image avec trop d'informations. Optez pour la simplicité quand c'est possible.",
        "N'hésitez pas à expérimenter avec différents cadrages pour trouver celui qui fonctionne le mieux.",
        "Inspirez-vous d'autres projets similaires pour des idées de présentation efficaces.",
      ],
      ctaCancel: "Annuler",
      ctaUpload: "Utiliser cette image",
    }
  }

  connect() {
    this.initializeContent()
    this.updateImageState()
  }

  disconnect() {
    this.cleanupCropper()
  }

  initializeContent() {
    if (this.hasAllRequiredTargets()) {
      this.titleTarget.textContent = this.texts.title
      this.tipsTitleTarget.textContent = this.texts.tipsTitle
      this.tipsTarget.innerHTML = this.texts.tips.map(tip => `<li>${tip}</li>`).join('')
      this.cancelButtonTarget.textContent = this.texts.ctaCancel
      this.uploadButtonTarget.textContent = this.texts.ctaUpload
    }
  }

  hasAllRequiredTargets() {
    return this.hasModalContainerTarget &&
      this.hasTitleTarget &&
      this.hasTipsTitleTarget &&
      this.hasTipsTarget &&
      this.hasUploadButtonTarget &&
      this.hasCancelButtonTarget
  }

  updateImageState() {
    if (this.imageValue) {
      this.uploadStateTarget.style.display = 'none'
      this.previewStateTarget.style.display = 'flex'
      this.previewImageTarget.style.backgroundImage = `url('${this.imageValue}')`
    } else {
      this.uploadStateTarget.style.display = 'flex'
      this.previewStateTarget.style.display = 'none'
      this.previewImageTarget.style.backgroundImage = ''
    }
  }

  cleanupCropper() {
    if (this.cropper) {
      this.cropper.destroy()
      this.cropper = null
    }
  }

  handleFileInputChange(event) {
    const file = event.target.files?.[0]
    if (!file) return

    this.cleanupCropper()

    if (this.hasImgContainerTarget) {
      this.imgContainerTarget.innerHTML = ''
      const image = document.createElement('img')
      this.imgContainerTarget.appendChild(image)

      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          this.cropper = new Cropper(image, {
            aspectRatio: this.ratioValue,
            viewMode: 2,
            autoCropArea: 1
          })
        }
      }
      reader.readAsDataURL(file)
    }

    if (this.hasModalContainerTarget) {
      this.modalContainerTarget.style.display = 'flex'
      this.modalContainerTarget.style.opacity = '1'
      this.modalContainerTarget.style.pointerEvents = 'auto'
    }
  }

  closeModal() {
    if (this.hasModalContainerTarget) {
      this.modalContainerTarget.style.display = 'none'
      this.modalContainerTarget.style.opacity = '0'
      this.modalContainerTarget.style.pointerEvents = 'none'
    }
    this.cleanupCropper()
  }

  upload() {
    if (!this.cropper) return

    const fileInput = document.getElementById('js-project-image-upload-input')
    const file = fileInput?.files[0]
    if (!file) return

    const cropData = this.cropper.getData(true)
    const formData = new FormData()

    formData.append('description[image]', file)
    formData.append('description[crop_x]', Math.round(cropData.x))
    formData.append('description[crop_y]', Math.round(cropData.y))
    formData.append('description[crop_w]', Math.round(cropData.width))
    formData.append('description[crop_h]', Math.round(cropData.height))

    this.setLoadingState(true)

    fetch(window.location.href, {
      method: 'PUT',
      headers: {
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (fileInput) {
          fileInput.value = ''
        }

        this.imageValue = data.image
        this.updateImageState()

        const event = new CustomEvent('cropper:uploaded', {
          bubbles: true,
          detail: { image: data.image }
        })
        this.element.dispatchEvent(event)

        this.closeModal()
      })
      .catch(error => {
        console.error("Upload error:", error)
        alert("Une erreur est survenue lors de l'envoi de l'image. Veuillez réessayer.")
      })
      .finally(() => {
        this.setLoadingState(false)
      })
  }

  setLoadingState(isLoading) {
    if (!this.hasUploadButtonTarget || !this.hasCancelButtonTarget) return

    if (isLoading) {
      this.uploadButtonTarget.classList.add('button-grey-outline-disabled')
      this.uploadButtonTarget.textContent = 'En cours de téléchargement...'
      this.cancelButtonTarget.classList.add('button-grey-outline-disabled')
    } else {
      this.uploadButtonTarget.classList.remove('button-grey-outline-disabled')
      this.uploadButtonTarget.textContent = this.texts.ctaUpload
      this.cancelButtonTarget.classList.remove('button-grey-outline-disabled')
    }
  }
}
