import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["content"]
  static values = {
    message: String
  }

  showTooltip() {
    if (this.messageValue) {
      this.contentTarget.innerHTML = this.messageValue;
      this.contentTarget.classList.add('block');
      this.contentTarget.classList.remove('hidden');
    }
  }

  hideTooltip() {
    this.contentTarget.classList.add('hidden');
    this.contentTarget.classList.remove('block');
  }
}