import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['tab']
  togglables = []
  connect() {
    this.tabTargets.forEach(tab => {
      this.togglables.push(tab.dataset.tabsSelector)
    })
  }

  toggle(event) {
    event.preventDefault()

    // Toggle tab classes
    this.tabTargets.forEach(tab => {
        tab.classList.remove('border-b-4', 'border-b-emerald-500')
        tab.classList.add('hover:border-b-4', 'hover:border-grey-200')
      }
    )
    event.currentTarget.classList.remove('hover:border-b-4', 'hover:border-grey-200')
    event.currentTarget.classList.add('border-b-4', 'border-b-emerald-500')

    // Toggle tab targets
    this.togglables.forEach(selector => document.querySelector(selector).classList.add('hidden'))
    document.querySelector(event.currentTarget.dataset.tabsSelector).classList.remove('hidden')
  }
}